require('./bootstrap');


// Site Javascript
var ggfunctions = {
	backButtonInit: function(){
		var selector = 'button.btn-back, .btn-back button';
		if(document.querySelector(selector)){
			document.querySelector(selector).addEventListener('click', function(e){
				e.preventDefault;
				this.disabled=true;
				if(window.history.length==0){
					window.location.href = '/';
				} else {
					window.history.back();
					setTimeout(function(){
						window.location.href = '/';
					}, 1000);
				}
			});
		}
	},
	clipboardInit: function(){
		var selector = '.copy-to-clipboard';
		if(document.querySelector(selector)){
			var buttonsClipboard = document.querySelectorAll(selector);
			for(var i=0;i<buttonsClipboard.length;i++){
				buttonsClipboard[i].addEventListener('click', function(e){
					e.target.parentNode.querySelector('.copy-to-clipboard-text').select();
					document.execCommand('copy');
				}, false);
			}
		}
	},
	viewPageHasDownloaded: false,
	viewPageInit: function(){
		
		// Update Download Count
		var buttonsDownload = document.querySelectorAll('.skin-file-download-action');
		if(buttonsDownload.length>0){
			// Send view event
			fetch('/stat', {
				method: 'POST', // or 'PUT'
				headers: {
				  'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					url: window.location.pathname
				})
			});
			
			// Send download event
			for(var i=0;i<buttonsDownload.length;i++){
				buttonsDownload[i].addEventListener('click', function(e){
					var countText = document.querySelectorAll('.skin-file-download-count');

					var count = countText[0].textContent;
					count = parseInt(count.replace(/[^0-9\.]+/, ''));
					countText[0].textContent = count + 1;
					
					// Send view event
					if(ggfunctions.viewPageHasDownloaded===false){
						ggfunctions.viewPageHasDownloaded = true;
						fetch('/stat', {
							method: 'POST', // or 'PUT'
							headers: {
							  'Content-Type': 'application/json'
							},
							body: JSON.stringify({
								url: window.location.pathname.replace(/view\//ig, 'download/')
							})
						});
					}
				}, false);
			}
		}
	},
	navInit: function(){
		// Sidebar toogle
		document.getElementById('toggle-sidebar').addEventListener('click', function(e){
			e.preventDefault();
			if(window.innerWidth<992){
				document.getElementById('sidebar').classList.toggle("mobile-open");
				window.scrollTo(0, 0);
			} else {
				document.getElementById('sidebar').classList.toggle("desktop-closed");
				if(localStorage){
					if(localStorage.getItem('desktop-sidenav')=='closed'){
						localStorage.setItem('desktop-sidenav', 'open');
					} else {
						localStorage.setItem('desktop-sidenav', 'closed');
					}
				}
			}
		});
		
		
		if(localStorage){
			if(localStorage.getItem('desktop-sidenav')=='closed'){
				document.getElementById('sidebar').classList.toggle("desktop-closed");
			}
		}
		
		// Search Function
		function autocomplete(inp, arr) {
			/*the autocomplete function takes two arguments,
			the text field element and an array of possible autocompleted values:*/
			var currentFocus;
			var lastSearchString = '';
			var xhr = null;
			var timeout = null;
			var previousResults = [];
			
			function renderListItem(row){
				var x = '<a class="dropdown-item" href="' + row.u + '">' + row.t;
				if(row.g=='yt'){
					x += ' <em style="color:#ff0000">[youtuber]</em>';
				} else if(row.g=='c'){
					x += ' <em style="color:#1258c5">[category]</em>';
				} else {
					x += ' <em style="color:green">[player]</em>';
				}
				x += '</a>';
				
				var b = document.createElement("LI");
				b.innerHTML = x;
				
				return b;
			}

			/*execute a function when someone writes in the text field:*/
			inp.addEventListener("input", function(e) {
				var a, b, i, val = this.value;
				var self = this;

				if(xhr!==null){
					xhr.abort();
					xhr = null;
				}

				if(timeout===null){
					clearTimeout(timeout);
				}

				if(val.length<3){
					return;
				}
				
				closeAllLists();
				
				// Prerender list
				var a = document.createElement("UL");
				a.setAttribute("id", self.id + "autocomplete-list");
				a.setAttribute("class", "autocomplete-items dropdown-menu show");
				a.setAttribute("style", "padding-bottom:0");
				self.parentNode.parentNode.appendChild(a);

				b = document.createElement("LI");
				b.innerHTML = '<li><em class="dropdown-item" style="color:grey;">... fetching suggestions</em></li>';
				a.appendChild(b);
				
				// Handle previous results
				if(val.substr(0, lastSearchString.length)==lastSearchString){
					if(previousResults && previousResults.length>0){
						for(i=0;i<previousResults.length;i++) {
							a.appendChild(renderListItem(previousResults[i]));
						}
					}
				}
				lastSearchString = val;

				// Do Fetch
				timeout = setTimeout(function(){
					xhr = new XMLHttpRequest();
					xhr.onreadystatechange = function() {
						if(xhr.readyState && xhr.readyState === 4){
							try {
								var data = JSON.parse(xhr.response);
							} catch (error) {
								return;
							}

							var b, i;
							/*close any already open lists of autocompleted values*/
							//closeAllLists();
							a.innerHTML = '';
							if (!val) {
								return false;}
							currentFocus = -1;

							//var isopen = false;
							var renderResults = [];
							if(data.items && data.items.length>0){
								renderResults = data.items;
								previousResults = data.items;
							} else if(previousResults && previousResults.length>0){
								b = document.createElement("LI");
								b.innerHTML = '<li><em class="dropdown-item" style="color:orange;">... no new suggestions</em></li>';
								a.appendChild(b);
								renderResults = previousResults;
							}
							
							if(renderResults && renderResults.length>0){
								for(i=0;i<renderResults.length;i++) {
									a.appendChild(renderListItem(renderResults[i]));
								}
							} else {
								b = document.createElement("LI");
								b.innerHTML = '<li><em class="dropdown-item" style="color:grey;">... no suggestions</em></li>';
								a.appendChild(b);
							}
							/*if(isopen){
								a.parentNode.classList.add('open');
							} else {
								a.parentNode.classList.remove('open');
							}*/

							clearTimeout(timeout);
						}
					};

					xhr.open('GET', '/search/suggest?q=' + encodeURIComponent(val), true);
					xhr.send('');
				}, 500);
			});
			/*execute a function presses a key on the keyboard:*/
			inp.addEventListener("keydown", function(e) {
				var x = document.getElementById(this.id + "autocomplete-list");
				if (x) x = x.getElementsByTagName("div");
				if (e.keyCode == 40) {
					/*If the arrow DOWN key is pressed,
					increase the currentFocus variable:*/
					currentFocus++;
					/*and and make the current item more visible:*/
					addActive(x);
				} else if (e.keyCode == 38) { /* up */
					/*If the arrow UP key is pressed,
					decrease the currentFocus variable:*/
					currentFocus--;
					/*and and make the current item more visible:*/
					addActive(x);
				} else if (e.keyCode == 13) {
					/*If the ENTER key is pressed, prevent the form from being submitted,*/
					/*e.preventDefault();
					if (currentFocus > -1) {
						if (x) x[currentFocus].click();
					}*/
				}
			});
			function addActive(x) {
				/*a function to classify an item as "active":*/
				if (!x) return false;
				/*start by removing the "active" class on all items:*/
				removeActive(x);
				if (currentFocus >= x.length) currentFocus = 0;
				if (currentFocus < 0) currentFocus = (x.length - 1);
				/*add class "autocomplete-active":*/
				x[currentFocus].classList.add("autocomplete-active");
			}
			function removeActive(x) {
				/*a function to remove the "active" class from all autocomplete items:*/
				for (var i = 0; i < x.length; i++) {
					x[i].classList.remove("autocomplete-active");
				}
			}
			function closeAllLists(elmnt) {
				/*close all autocomplete lists in the document,
				except the one passed as an argument:*/
				var x = document.getElementsByClassName("autocomplete-items");
				for (var i = 0; i < x.length; i++) {
					if (elmnt != x[i] && elmnt != inp) {
						x[i].parentNode.removeChild(x[i]);
					}
				}
			}
			/*execute a function when someone clicks in the document:*/
			document.addEventListener("click", function (e) {
				closeAllLists(e.target);
			});
		}

		/*initiate the autocomplete function on the "myInput" element, and pass along the countries array as possible autocomplete values:*/
		autocomplete(document.getElementById('mainsearch'));
		document.getElementById('mainsearch').parentNode.parentNode.style.position = 'relative';
	},
	init: function(){
		ggfunctions.navInit();
		ggfunctions.backButtonInit();
		ggfunctions.clipboardInit();
		ggfunctions.viewPageInit();
	}
};

ggfunctions.init();
